//import { IonicMqttModule, MQTTService } from 'ionic-mqtt';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from  '@angular/common/http';
import { Plugins, ActionSheetOptionStyle } from '@capacitor/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { SignaturePadModule } from 'angular2-signaturepad';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';




const { Geolocation, Modals } = Plugins;

const config: SocketIoConfig = { url: 'https://socketserver.pickupwm.ubq.cl', options: {withCredentials: false} };

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [ HttpClientModule,BrowserModule, SignaturePadModule, IonicModule.forRoot(), AppRoutingModule, ServiceWorkerModule.register('combined-sw.js', {
    enabled: environment.production,
  }),
  AngularFireMessagingModule, ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),SocketIoModule.forRoot(config),IonicStorageModule.forRoot()],
  schemas :[CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },BarcodeScanner],
  bootstrap: [AppComponent],
})
export class AppModule {}
