import { Component } from '@angular/core';
import { PickupService} from '../app/api/pickup.service';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import {SwUpdate} from '@angular/service-worker';
import { HttpClient,HttpParams } from '@angular/common/http';
import packagejson from '../../package.json';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appVersion: string = packagejson.version;
  
  public appPages = [
    { title: 'Inicio', url: '', icon: 'home' },
   // { title: 'Barreras', url: '/puertas', icon: 'car' },
    { title: 'Buscar', url: '/buscar', icon: 'search' },
    { title: 'Cola envío', url: '/colaenvio', icon: 'hourglass' },
//    { title: 'Picker', url: '/picker', icon: 'barcode' },
    { title: 'Recepción Baby Tuto', url:'/compras-preparacion'}

  ];

  nombre_usuario:string='';
  nombre_local:string='';

  constructor(private menu: MenuController, readonly swUpdate:SwUpdate,private router: Router, public pickupService : PickupService,public http: HttpClient) {

      this.nombre_usuario = this.pickupService.nombre_usuario;
      this.nombre_local   = this.pickupService.nombre_local;
  }


  logout(){

    this.menu.enable(false, 'custom');

    this.pickupService.logout().subscribe(data=>{
      this.router.navigate(['/login']);
    })

/*
    this.pickupService.cancelarSuscripcion();
    this.pickupService.usuario={'id':0,'logeado':0,'rut':'','nombre_usuario':'','celular':'','codigo_local':0,'id_tienda':0,'nombre_local':'','username':''};
    this.pickupService.runner = {'id':'','nombre':''};
    this.pickupService.stopAudio();
    this.pickupService.socket.emit('leave', this.pickupService.local);
    this.pickupService.socket.disconnect();
    localStorage.removeItem('token');

    let url = "https://clicyretira.ubq.cl/api/dispositivo/eliminartelefono";

    this.http.post(url,{local:this.pickupService.local, celular:this.pickupService.celular}).subscribe(data=>{

    });

    Storage.remove({ key: 'usuario' }).then(data=>{
      this.router.navigate(['/login']);
    });*/
  }

  desasociarCelular(){
  }


  checkForUpdate() {
/*
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(() => {
          console.log('Checking for updates...');
          console.log('Updating to latest version.');
          this.swUpdate.activateUpdate().then(() => document.location.reload());
  
          
      }).catch((err) => {
          console.error('Error when checking for update', err);
      });
    }*/
  }

}
