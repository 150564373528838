import { Injectable } from "@angular/core";
import { Plugins } from "@capacitor/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Socket } from "ngx-socket-io";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Howl } from "howler";
import { Platform, LoadingController, ToastController } from "@ionic/angular";
import { timeout } from "rxjs/operators";
const { Storage, Network, Modals } = Plugins;
import { SwUpdate } from "@angular/service-worker";
import { Md5 } from "ts-md5/dist/md5";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PickupService {

  token:string='';
  online: boolean = true;
  socketStatus = false;
  badlogin = false;
  usuario = {
    id: 0,
    logeado: 0,
    rut: "",
    nombre_usuario: "",
    celular: "",
    codigo_local: 0,
    id_tienda: 0,
    nombre_local: "",
    username:""
  };

  celular: string = "";
  nombre_usuario: string = "";
  runner = { id: "", nombre: "" };
  rutRunner: string = "";

  nombre_local: string = "Local 100";
  logeado: boolean = false;
  loading: boolean = false;
  player: Howl;

  local = 100;
  pedidoActivo = {
    sg123: "",
    nombrecliente: "",
    status: "",
    estado_cliente: 0,
    runner: "",
    id_runner: 0,
    celular: "",
    rut: "",
    imagen_firma: "",
    orden: "",
    fecharetiro: "",
    fecha: "",
    fecha_runner: "",
    tiempo: "",
    ventana: "",
    segundos: "",
    hora_llegada_estimada: "",
    eta_slug: "",
    status_runner: "",
    comentario_runner: "",
    ubicacion_bodega: "",
    modo_retiro: "",
    zona_entrega: "",
    rut_retira: "",
    patente_retira: "",
    ubicaciones: "",
    bultos: [],
    productos:[]
  };
  pedidosEsperando = [];
  pedidosProgramados = [];
  pedidosEnCamino = [];
  pedidosPreparacion = [];
  colaFirma = [];
  colaImagen = [];

  audio: any;
  obj: any;
  currentUser: String;
  intervalCola;
  intervalProcesarFirma;
  intervalProcesarFoto;
  coord: string = "";

  codigoBulto: String = "";
  codigoPosicion: String = "";

  loadingPopup: any;
  loadingLabel:string='Sincronizando...';

  constructor(
    public toastController: ToastController,
    private platform: Platform,
    readonly swUpdate: SwUpdate,
    public loadingController: LoadingController,
    public http: HttpClient,
    public socket: Socket,
    private router: Router
  ) {
    this.initializeApp();
    const _self = this;
    this.swUpdate.available.subscribe((event) => {
      _self.swUpdate.activateUpdate().then((data) => {
        this.showAlert().then((res) => {
          document.location.reload();
        });
      });
    });

    this.swUpdate.checkForUpdate();

    let handler = Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
      this.online = status.connected;
    });

    this.intervalCola = setInterval(() => {
      this.actualizarTiempoEspera();
    }, 1000);

    this.intervalProcesarFirma = setInterval(() => {
      if (this.online == true && this.colaFirma.length > 0) {
        this.procesarCola();
      }
    }, 2000);

    this.intervalProcesarFoto = setInterval(() => {
      if (this.online == true && this.colaImagen.length > 0) {
        this.procesarColaFoto();
      }
    }, 20000);

  }

  initializeApp() {
    //Subscribe on pause i.e. background or lock phone
    this.platform.pause.subscribe(() => {
      console.log("pause");
    });
    //Subscribe on pause i.e. background or unlock phone
    this.platform.resume.subscribe(() => {
      this.syncronizarBD();
    });

    // this.platform.resize.subscribe(()=>{
    //   this.syncronizarBD();
    // });
  }

  conectarSocket() {
    console.log(this.usuario);
    console.log("conectar a local :" + this.usuario.codigo_local);
    var status = this.socket.connect();
    console.log(status);
    if (status) {
      this.socketStatus = true;
    }

    this.socket.on("disconnect", () => {
      this.socketStatus = false;
      console.log("socket off");
    });

    this.socket.on("cliente_en_camino", () => {});

    this.socket.on("connect", () => {
      this.socketStatus = true;

      this.socket.emit("join", this.usuario.codigo_local);
      console.log("connect to :" + this.usuario.codigo_local);
      console.log("socket on");
    });

    this.socket.on("error", (error) => {
      console.log(error);
    });

    this.socket.fromEvent("notificacion_llegada").subscribe(
      (message: string) => {
        var aux = JSON.stringify(message);
        var _aux = JSON.parse(aux);
        console.log("Mensaje de socket..");
        console.log(aux);

        if (_aux.msg == "updateapp") {
          this.swUpdate.checkForUpdate();
        } else if (_aux.msg == "actualizar") {
          this.syncronizarBD();
        } else if (_aux.msg == "cliente_en_camino") {
          this.playAudio("beep.mp3");
          this.syncronizarBD();
        } else if (_aux.msg == "llegada_cliente") {
          if (this.pedidosEsperando.length == 0) {
            this.playAudio("ring1.mp3");
            this.syncronizarBD();
            this.router.navigate(["/llegadacliente"]);
          } else {
            this.playAudio("beep.mp3");
            this.presentToast();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );

    let name = `user-${new Date().getTime()}`;
    this.currentUser = name;
    this.socket.emit("set-name", name);
  }

  playAudio(file: string) {
    this.player = new Howl({
      src: ["assets/sound/" + file],
    });

    this.player.play();
  }

  stopAudio() {
    // this.player.stop();
  }

  async loginQr(qr_string, rut_runner) {
    // this.loading=true;
    console.log(rut_runner);
    let url = environment.api_host+"/api/dispositivos/login-qr";

    this.http.post(url, { qr: qr_string }).subscribe((data) => {
      if (data) {
        let respuesta = JSON.parse(JSON.stringify(data));

        this.usuario = {
          id: respuesta.usuario.id,
          codigo_local: respuesta.local_codigo,
          id_tienda: respuesta.local_codigo,
          rut: rut_runner,
          nombre_local: respuesta.nombre_local,
          nombre_usuario: "",
          celular: this.celular,
          username:"",
          logeado: 1,
        };
        console.log(this.usuario);

        this.local = respuesta.local_codigo;
        this.nombre_usuario = rut_runner;
        this.logeado = true;

        this.setObject("logeado", true);
        this.setObject("usuario", this.usuario);
        this.setObject("celular", { celular: this.celular });
        this.conectarSocket();

        this.router.navigate(["/"]);
      } else {
        console.log("qr invalido");
      }
    });
  }

  async login(form) {
    this.loading = true;
    const headers = { 'Authorization': '96d7c9e3306ef575ce2d953a0674a829748d039e','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };
    let url = environment.api_host+"/api-app/login-pwa-runner";
    let email = form.value.email;
    let password = form.value.password;
    this.celular = form.value.celular;

    this.http
      .post(url, {
        username: email,
        password: password,
        celular: "56" + this.celular,
      },{headers})
      .subscribe((data) => {
        this.loading = false;

        let respuesta = JSON.parse(JSON.stringify(data));

        if (respuesta.status == true) {
          this.local = respuesta.usuario.id_tienda;
          this.nombre_usuario = respuesta.usuario.name;
          this.logeado = true;

          this.usuario = {
            id: respuesta.usuario.id,
            codigo_local: respuesta.usuario.id_tienda,
            id_tienda: respuesta.usuario.id_tienda,
            nombre_local: respuesta.usuario.nombre_local,
            rut: "",
            nombre_usuario: respuesta.usuario.name,
            celular: this.celular,
            logeado: 1,
            username:respuesta.usuario.username
          };

          localStorage.setItem('token',respuesta.usuario.token);

          console.log(this.usuario);

          localStorage.setItem("usuario", JSON.stringify(this.usuario));
          this.setObject("logeado", true);
          this.setObject("usuario", this.usuario);
          this.setObject("celular", { celular: this.celular });

          this.conectarSocket();

          this.router.navigate(["/"]);
        } else {
          this.badlogin = true;
        }
      });
  }

  async syncronizarBD(from_login: number = 0, loading_screen: number = 0) {
    var _self = this;
    var token = localStorage.getItem('token');
    let url = environment.api_host+"/api-app/pedidos-pwa-runner";
    const headers = { 'Authorization': '96d7c9e3306ef575ce2d953a0674a829748d039e','Content-Type':'application/json','Access-Control-Allow-Origin':'*','Access-Control-Allow-Headers':'*' };
      
   // await this.loadingScreen();
    this.loadingLabel='Sincronizando...';
    console.log('Enviar....');
    this.http.post(url,{token:token,local_codigo:this.usuario.codigo_local,username:this.usuario.username},{headers}).pipe(timeout(10000))
      .subscribe(async (data) => {
       
        //await _self.dismissScreen();
        this.loadingLabel='';
       
        var pedidos = JSON.parse(JSON.stringify(data));

        console.log(pedidos);

        if(!pedidos.autorizado){
            this.logout().subscribe(data=>{
              this.router.navigate(['/login']);
            });

        }else{


                    pedidos.pedidos_esperando.forEach(function (value) {
                        value.bultos.forEach(function (bulto) {
                          if (bulto.en_carro == 1) bulto.en_carro = true;
                          else bulto.en_carro = false;
                        });

                        var _hora_llegada = moment(
                          value.ts_llegada_totem,
                          "YYYY-MM-DD HH:mm:ss"
                        );
                        var _hora_actual = moment.utc();

                        try {
                          var duration = moment.duration(_hora_actual.diff(_hora_llegada));
                          value.tiempo = _self.formatoSegundos(
                            Math.round(duration.asSeconds())
                          );
                          value.segundos = duration.asSeconds();
                          let diferencia_dias_ventana = (new Date().getDate()) - (new Date(value.ventana_desde).getDate());
                          value.ventana = (diferencia_dias_ventana <= 0 ? value.ventana :
                                          diferencia_dias_ventana == 1 ? `${value.ventana} (ayer)`:
                                          diferencia_dias_ventana == 2 ? `${value.ventana} (anteayer)` : `${value.ventana} ${value.ventana_desde.substr(0,10)}`);
                        }catch(error){}
                    });


                    pedidos.pedidos_programados.forEach(function (value) {
                      value.bultos.forEach(function (bulto) {
                        if (bulto.en_carro == 1) bulto.en_carro = true;
                        else bulto.en_carro = false;
                      });
                  });

                    
                    this.pedidosEsperando=pedidos.pedidos_esperando;
                    this.pedidosProgramados=pedidos.pedidos_programados;

          }
        
      });

    this.http.post(environment.api_host+"/api-app/get-pedidos-recepcion",{token:token,local_codigo:this.usuario.codigo_local,username:this.usuario.username},{headers}).pipe(timeout(10000))
      .subscribe(async (data:any) => {

            this.pedidosPreparacion=data.pedidos;
            console.log(data);
      });

  }

  async getPedidos() {
    const { value } = await Storage.get({ key: "pedidos_esperando" });

    if (value) {
      this.pedidosEsperando = JSON.parse(value);
    }
  }

  async agregarCola(item) {
    let params = {
      orden: item.orden,
      imagen_firma: item.imagen_firma,
      fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
      fecha_runner: moment().format("YYYY-MM-DD HH:mm:ss"),
      evidencia_entrega: item.evidencia_entrega,
    };

    this.colaFirma.push(params);
    this.colaImagen.push(params);

    this.setObject("cola_firma", this.colaFirma);
    this.setObject("cola_imagen", this.colaImagen);
  }

  eliminarOrdenCola(orden) {
    var tmp = [];
    this.colaFirma.forEach(function (value) {
      if (value.orden != orden) {
        tmp.push(value);
      }
    });
    this.colaFirma = tmp;
    this.setObject("cola_firma", this.colaFirma);
  }


  eliminarFotoCola(orden) {
    var tmp = [];
    this.colaImagen.forEach(function (value) {
      if (value.orden != orden) {
        tmp.push(value);
      }
    });
    this.colaImagen = tmp;
    this.setObject("cola_imagen", this.colaImagen);
  }

  eliminarPedidoEsperando(orden) {
    console.log('ELIMINANDO PEDIDO : '+orden)
    var tmp = [];
    this.pedidosEsperando.forEach(function (value) {
      if (value.orden != orden) {
        tmp.push(value);
      }
    });
    this.pedidosEsperando = tmp;

    this.setObject("pedidos_esperando", this.pedidosEsperando);
  }


  async procesarColaFoto(){
    var _self = this;
    let md5 = new Md5();
    if (this.colaImagen.length > 0) {
      this.colaImagen.forEach(function (value) {
            let httpParamsImagen = new HttpParams()
            .append("orden", value.orden)
            .append("imagen_firma", value.imagen_firma);
            let encrypted_64base = md5.appendStr(value.imagen_firma).end().toString();
            _self.http
              .post(
                environment.api_host+"/api/firmar-entrega-app-socket-imagen",
                httpParamsImagen
              )
              .subscribe((data) => {
                if(data == encrypted_64base){
                  _self.eliminarFotoCola(value.orden);
                }else{
                  console.log('Posible error al enviar la foto. Los MD5 no coinciden');
                }
              });

      });
    }

  }

  async procesarCola() {
    var _self = this;
    var fecha_disp = moment().format("YYYY-MM-DD HH:mm:ss");
    
    if (this.colaFirma.length > 0) {
        this.colaFirma.forEach(function (value) {
          
          
          let url = environment.api_host+"/api/firmar-entrega-app-socket";
          let httpParams = new HttpParams()
            .append("runner_id", _self.usuario.id.toString())
            .append("orden", value.orden)
            .append("fecha", value.fecha)
            .append("fecha_runner", value.fecha_runner)
            .append("fecha_disp", fecha_disp)
            .append("evidencia_entrega", value.evidencia_entrega)
            .append("rut_retira", value.rut_retira)
            .append("patente_retira", value.patente_retira)
            .append("version_app", value.version);

          _self.http.post(url, httpParams).subscribe((data) => {

            _self.eliminarOrdenCola(value.orden);
            _self.getPedidos();
            
          });
        });
      }
  }

  async entregarPedido(item) {
    this.agregarCola(item)
      .then((data) => {
        console.log('FIN AGREGAR COLA '+item.orden);
        this.eliminarPedidoEsperando(item.orden);
         this.procesarCola();
        //this.agregarCola(item);
      });
  }

  async actualizarTiempoEspera() {
    var _self = this;

    this.pedidosEsperando.forEach(function (value) {
      var _hora_llegada = moment(value.ts_llegada_totem, "YYYY-MM-DD HH:mm:ss");
      try {
        var _hora_actual = moment();
        var duration = moment.duration(_hora_actual.diff(_hora_llegada));
        value.tiempo = _self.formatoSegundos(Math.round(duration.asSeconds()));
        value.segundos = duration.asSeconds();
      } catch (error) {}
    });
  }

  formatoSegundos(sec) {
    var date = new Date(null);
    date.setSeconds(sec);
    return date.toISOString().substr(11, 8);
  }

  async setObject(key: string, value) {
    await Storage.set({
      key: key,
      value: JSON.stringify(value),
    });
  }

  async getObject(key) {
    const { value } = await Storage.get({ key: key });
    return value;
  }

  notificacion() {
    Notification.requestPermission(function (result) {
      if (result === "granted") {
        navigator.serviceWorker.ready.then(function (registration) {
          registration.showNotification("Notificacion Pickup", {
            body: "Cliente esperando!",
            requireInteraction: true,
            icon: "assets/images/pickup_small.png",
            vibrate: [200, 100, 200, 100, 200, 100, 200],
            tag: "vibration-sample",
          });
        });
      }
    });
  }

  async showAlert() {
    let alertRet = await Modals.alert({
      title: "Actualización",
      message: "Se ha detectado una nueva actualización",
    });
  }

  async showConfirm() {
    let confirmRet = await Modals.confirm({
      title: "Actualización",
      message: "Are you sure you'd like to press the red button?",
    });
  }

  //PUSH

  getPushPublicKey() {
    var key =
      "BI8z1gjNgVEW-miOeI7KIkPyX4cA0uyRvSQLWoGY7qQefTHi5Kpy7KIs3IUPd4mPpkQvb3Oh0aezImzDrr7ufxw";
    return key;
    //return fetch('http://localhost:3000/api/key').then(res=>res.arrayBuffer()).then(key=>new Uint8Array(key));
  }

  cancelarSuscripcion() {}

  //SW

  checkUpdate() {
    /*
    this.updates.checkForUpdate().then(res=>{
      console.log(res);
    });*/
  }

  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: "my-custom-class",
      message: "Por favor espere...",
      duration: 1000,
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log("Loading dismissed!");
  }

  async loadingScreen() {
    this.loadingPopup = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Por favor espere...',
      duration: 25000
    });
    await this.loadingPopup.present();
    console.log('Loading...');
  }


  

  async dismissScreen() {
    await this.loadingPopup.dismiss();
    console.log('Dismissed Loading');
  }

  async presentToast() {
    const toast = await this.toastController.create({
      message: "Ha llegado un nuevo cliente...",
      duration: 2000,
    });
    toast.present();
  }


  logout(){

    this.cancelarSuscripcion();
    this.usuario={'id':0,'logeado':0,'rut':'','nombre_usuario':'','celular':'','codigo_local':0,'id_tienda':0,'nombre_local':'','username':''};
    this.runner = {'id':'','nombre':''};
    this.stopAudio();
    this.socket.emit('leave', this.local);
    this.socket.disconnect();
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('_cap_usuario');
    localStorage.removeItem('_cap_logeado');

    let url = environment.api_host+"/api/dispositivo/eliminartelefono";

    return this.http.post(url,{local:this.usuario.codigo_local, celular:this.usuario.celular});



  }
}
