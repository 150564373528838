import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
 /* {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },*/
  {
    path: '',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'opciones',
    loadChildren: () => import('./opciones/opciones.module').then( m => m.OpcionesPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'resultado',
    loadChildren: () => import('./resultado/resultado.module').then( m => m.ResultadoPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'llegadacliente',
    loadChildren: () => import('./llegadacliente/llegadacliente.module').then( m => m.LlegadaclientePageModule)
  },
  {
    path: 'firma',
    loadChildren: () => import('./firma/firma.module').then( m => m.FirmaPageModule)
  },
  {
    path: 'opcionesnollegada',
    loadChildren: () => import('./opcionesnollegada/opcionesnollegada.module').then( m => m.OpcionesnollegadaPageModule)
  },
  {
    path: 'runners',
    loadChildren: () => import('./runners/runners.module').then( m => m.RunnersPageModule)
  },
  {
    path: 'menumodulo',
    loadChildren: () => import('./menumodulo/menumodulo.module').then( m => m.MenumoduloPageModule)
  },
  {
    path: 'bodega',
    loadChildren: () => import('./bodega/bodega.module').then( m => m.BodegaPageModule)
  },
  {
    path: 'rut-runner',
    loadChildren: () => import('./rut-runner/rut-runner.module').then( m => m.RutRunnerPageModule)
  },
  {
    path: 'scanqr',
    loadChildren: () => import('./scanqr/scanqr.module').then( m => m.ScanqrPageModule)
  },
  {
    path: 'nombre-runner',
    loadChildren: () => import('./nombre-runner/nombre-runner.module').then( m => m.NombreRunnerPageModule)
  },
  {
    path: 'reagendar',
    loadChildren: () => import('./reagendar/reagendar.module').then( m => m.ReagendarPageModule)
  },
  {
    path: 'comentario',
    loadChildren: () => import('./comentario/comentario.module').then( m => m.ComentarioPageModule)
  },{
    path: 'puertas',
    loadChildren: () => import('./puertas/puertas.module').then( m => m.PuertasPageModule)
  },
  {
    path: 'comentario',
    loadChildren: () => import('./modales/comentario/comentario.module').then( m => m.ComentarioPageModule)
  },
  {
    path: 'buscar',
    loadChildren: () => import('./buscar/buscar.module').then( m => m.BuscarPageModule)
  },
  {
    path: 'info-retira',
    loadChildren: () => import('./info-retira/info-retira.module').then( m => m.InfoRetiraPageModule)
  },
  {
    path: 'instrucciones-foto',
    loadChildren: () => import('./modales/instrucciones-foto/instrucciones-foto.module').then( m => m.InstruccionesFotoPageModule)
  },
  {
    path: 'picker-manual',
    loadChildren: () => import('./picker-manual/picker-manual.module').then( m => m.PickerManualPageModule)
  },
  {
    path: 'picker-manual-bultos',
    loadChildren: () => import('./picker-manual-bultos/picker-manual-bultos.module').then( m => m.PickerManualBultosPageModule)
  },
  {
    path: 'colaenvio',
    loadChildren: () => import('./colaenvio/colaenvio.module').then( m => m.ColaenvioPageModule)
  },
  {
    path: 'picker',
    loadChildren: () => import('./picker/picker.module').then( m => m.PickerPageModule)
  },
  {
    path: 'compras-preparacion',
    loadChildren: () => import('./compras-preparacion/compras-preparacion.module').then( m => m.ComprasPreparacionPageModule)
  },
  {
    path: 'picker-manual-prep',
    loadChildren: () => import('./picker-manual-prep/picker-manual-prep.module').then( m => m.PickerManualPrepPageModule)
  },
  {
    path: 'picker-manual-bultos-prep',
    loadChildren: () => import('./picker-manual-bultos-prep/picker-manual-bultos-prep.module').then( m => m.PickerManualBultosPrepPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
